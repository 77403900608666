import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://68e6a765a4eb2dba84cdca65b86bb050@o437901.ingest.us.sentry.io/4507141730992128",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});